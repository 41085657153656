@import "../../../node_modules/sweetalert2/dist/sweetalert2.css";

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

@mixin btn-variant($background, $border, $background-hover: darken($background, 5%), $border-hover: darken($border, 7.5%), $background-active: darken($background, 7.5%), $border-active: darken($border, 10%)) {
    background-color: $background;
    border: $border;

    &:focus,
    &.focus,
    &:hover {
        background-color: $background-hover;
        border: $border-hover;
    }

    &:active,
    &.active {
        background-color: $background-active;
        border: $border-active;
    }
}

@-webkit-keyframes swal2-animate-success-line-long {
    0% {
        top: 3.4em;
        right: 2.6em;
        width: 0;
    }
    65% {
        top: 3.4em;
        right: 2.6em;
        width: 0;
    }
    84% {
        top: 2.4375em;
        right: 0;
        width: 3.4375em;
    }
    100% {
        top: 2.4em;
        right: .25em;
        width: 2.9375em;
    }
}

@keyframes swal2-animate-success-line-long {
    0% {
        top: 3.4em;
        right: 2.6em;
        width: 0;
    }
    65% {
        top: 3.4em;
        right: 2.6em;
        width: 0;
    }
    84% {
        top: 2.4375em;
        right: 0;
        width: 3.4375em;
    }
    100% {
        top: 2.4em;
        right: .25em;
        width: 2.9375em;
    }
}

.swal2-popup {
    .swal2-styled {
        &.swal2-confirm,
        &.swal2-cancel {
            font-size: 14px;
            border-radius: 3px;
            transition: all .15s ease-in-out;
            &.danger {
                @include btn-variant($red, darken($red, 1%));
            }

            &.info {
                @include btn-variant($cyan, darken($cyan, 1%));
            }

            &.primary {
                @include btn-variant($blue, darken($blue, 1%));
            }

            &.warning {
                @include btn-variant($yellow, darken($yellow, 1%));
            }
        }

        &:focus {
            box-shadow: 0 0 0 2px #FFFFFF, 0 0 0 3px rgba(50, 100, 150, 0.4);
        }
    }

    .swal2-title {
        font-size: 24px!important;
        margin-bottom: 15px!important;
    }

    .swal2-content {
        font-size: 18px!important;
    }

    .swal2-validation-message {
        font-size: 14px!important;
    }
}

.swal2-icon {
    &.swal2-success [class^='swal2-success-line'][class$='long'] {
        top: 2.4em;
        right: .25em;
        width: 2.9375em;
    }
}